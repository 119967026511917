import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

const ArtTimePeriodPost = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout>
            <h1>{post.frontmatter.title}</h1>
            <p>{post.frontmatter.description}</p>
        </Layout>
    )
}

export default ArtTimePeriodPost

export const pageQuery = graphql`
    query ArtTimePeriodPostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                description
            }
        }
    }
`
